import { storeToRefs } from "pinia";
import { useMerchantStore } from "@/stores/merchant";

export default defineNuxtRouteMiddleware((to) => {
  const { profile } = storeToRefs(useMerchantStore()); // make authenticated state reactive

  if (profile.value.kyc_status !== "final" && to.path !== "/") {
    return navigateTo("/");
  }
});
